@import './variables.scss';

.header {
    &_logo {
        &_image {
            display: block;
            width: 100%;
            height: auto;
            border: 0;
        }
    }

    &_title {
        font-family: $font-secondary;
        margin: 0;
        display: block;
        font-size: 23px;
        font-weight: 500;
        color: #333;
    }

    &_register {
        text-align: right;
        margin-top: 10px;
        &_button {
            background-color: $color-blue;
            border-radius: 5px;
            padding: 10px 16px;
            font-size: 19px;
            line-height: 1.33;
            color: #fff;
            border-color: transparent;
            margin-top: 5px;

            &:hover,
            &:hover {
                color: #fff;
                background-color: $color-teal-dirty;
            }
        }
    }

    &_language {
        cursor: pointer;
        padding: 0 15px;
        margin-bottom: 10px;
    }
}
