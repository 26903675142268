$words-width: 6.2em, 5.5em, 8em, 3em, 5.7em, 7.1em, 6.6em, 5em, 7em, 2em, 5.1em,
    7.5em, 6em;

.mock {
    &__word {
        display: inline-block;
        border-radius: 6px;
        height: 1.2em;
        background-color: #ccc;
        min-width: 1em;
        margin-right: 0.4em;
        opacity: 0.25;
        @for $i from 1 through length($words-width) {
            $width: nth($words-width, $i);
            &:nth-child(#{$i}n) {
                width: $width;
            }
        }
    }
    &--title {
        opacity: 0.5;
        font-size: 1.3em;
        margin-bottom: 0.1em;
    }
    &__image {
        width: 100%;
        background-color: #eee;
        border-radius: 6px;
        opacity: 0.6;
        &:after {
            content: '';
            display: block;
            padding-bottom: 50%;
        }
    }
    &__panel {
        width: 100%;
        background-color: rgba(white, 0.6);
        border-radius: 6px;
        padding: 1em;
        margin-bottom: 1em;
    }
}
