$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Domine', sans-serif;

$color-gray: #333;
$color-lgray-border: #eef1f3;
$color-lgray: rgba(238, 241, 243, 0.6);
$color-blue: #009ae0;
$color-teal: #2ec5cc;
$color-teal-dirty: #259da2;

$color-primary: $color-teal;
