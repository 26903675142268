@import './variables.scss';

.sidebar {
    &_panel {
        box-shadow: initial;
        border: 1px solid #eef1f3;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.95);
        padding: 15px;
    }

    &_title {
        font-family: 'Domine', sans-serif;
        margin-top: 0;
        font-size: 24px;
        margin-bottom: 0;
        display: inline-block;
        font-weight: 500;
        color: $color-gray;
    }

    &_reviews {
        box-shadow: initial;
        border: 1px solid #eef1f3;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.95);
        padding: 15px;
        display: grid;
        grid-gap: 10px;
        text-align: center;
        @media (max-width: 1199px) {
            align-content: center;
            justify-content: center;
        }
        .reviews-rating {
            text-align: left;
            strong {
                font-size: 14px;
                color: gray;
            }
        }
        .text-link {
            margin-bottom: 0;
        }

        &_stars {
            font-weight: 900;
            font-size: 22px;

            i {
                margin: -3px;
            }
        }

        strong {
            font-size: 12px;
            color: $color-gray;
            margin: 0 5px;
        }

        &_button {
            background: $color-teal;
            color: white;
            padding: 5px 7px;
            border-radius: 7px;
            border: none;
            text-transform: uppercase;
            font-weight: 500;
            transition: 0.2s;
            white-space: nowrap;

            i {
                margin-top: -2px;
            }
        }
    }

    &_days,
    &_services {
        &_list {
            margin: 0;
            padding-left: 0;
            display: block;
            list-style-type: disc;

            &:first-child {
                border-top-right-radius: 2px;
                border-top-left-radius: 2px;
            }

            & > li:nth-child(even) {
                background: $color-lgray;
            }

            li {
                border: none;
                border-radius: 7px;
                background: none;
                overflow: auto;
                position: relative;
                display: block;
                padding: 10px 15px;
                margin-bottom: -1px;
                text-align: -webkit-match-parent;
            }
        }
    }
    &_offices {
        padding: 0;
        &_item {
            margin-bottom: 20px;
            p {
                margin: 0 0 10.5px;
            }
            ul {
                margin: 0;
                padding-left: 0;
                list-style-type: none;

                li {
                    padding: 3px 0;
                    margin-bottom: 0;

                    &:last-child {
                        border-bottom-right-radius: 2px;
                        border-bottom-left-radius: 2px;
                    }

                    &:first-child {
                        border-top-right-radius: 2px;
                        border-top-left-radius: 2px;
                    }
                }
            }
        }
    }
}

.day-item {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.time-range-wrapper {
    display: grid;
    grid-gap: 2px;
}
