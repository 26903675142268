@import './variables.scss';

.pagination {
    display: flex;
    grid-gap: 5px;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;

    &__control,
    &__page {
        min-width: 32px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary;
    }
    &__pages {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__control {
        font-weight: 900;
        font-size: 1.2em;
    }
    &__page {
        background-color: white;
        border: 1px solid #eef1f3;
        border-radius: 6px;
        margin: 3px;
        &--active,
        &:hover {
            font-weight: 700;
            color: white;
            background-color: $color-primary;
        }
    }
}
