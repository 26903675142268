@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Domine&family=Roboto:wght@300;400;900&display=swap');

* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    position: relative;
    font-family: "Roboto",sans-serif;
    padding-top: 60px;
    font-size: 17px;
    font-weight: 300;
    color: #333;
    margin: 0;
    line-height: 1.42857;
    background-color: #f9fcfd;
    scroll-behavior: smooth;
}

.container {
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 100%;
    }
    @media (min-width: 1200px) {
        width: 100%;
    }
    @media (min-width: 1366px) {
        width: 1250px;
    }

    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.leaflet-container {
    width: 100%;
    height: 300px;
}
// .register-widget-wrapper {
//     iframe {
//         height: auto !important;
//     }
// }
.text-teal {
    color: #2ec5cc;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background-color: rgba($color-primary, 0.2);
}
::-webkit-scrollbar-thumb {
    background-color: $color-primary;
}

::selection {
    color: black;
    background-color: $color-primary;
}


.app {
    display: grid;
    grid-template-columns: [start] 66% [mid] auto [end];
    grid-gap: 30px;
    align-items: flex-start;
    padding-bottom: 80px;
    @media (max-width: 991px) {
        grid-template-columns: [start] auto [end];
        padding-bottom: 100px;
    }
    @media (max-width: 767px) {
        padding-bottom: 30px;
    }
    &--no-sidebar {
        grid-template-columns: [start] auto [end];
    }
    &__header {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 180px auto 200px;
        position: relative;
        grid-column: start/end;
        margin-bottom:12px;
        @media (max-width: 1199px) {
            padding-top: 30px;
        }
        @media (max-width: 800px) {
            grid-template-columns: auto;
            grid-gap: 15px;
        }
        img {
            display: block;
            width: 100%;
            @media (max-width: 767px) {
                max-width: 140px;
            }
        }
        .banner {
            width: 100%;
            @media (max-width: 800px) {
                max-width: 180px;
            }
        }
        .app__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .header_register {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 1199px) {
                grid-column: 3;
            }
            @media (max-width: 800px) {
                grid-column: 1;
                display: flex;
                justify-content: center;
            }
        }
        .header_language {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    &__content {
        display: grid;
        grid-gap: 30px;
    }
    &__sidebar {
        display: grid;
        grid-gap: 22px;
        padding-left: 0;
        padding-right: 0;
        @media (min-width: 1200px) {
            padding-right: 0px;
        }
        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
            padding-top: 70px;
        }
        @media (max-width: 767px) {
            padding-top: 50px;
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__footer {
        grid-column: start/end;
        padding: 2vw 0;
    }
}

.page_title {
    display: inline-block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 14px;
    color: #333;
    margin-bottom: 14px;
    font-family: $font-secondary;
}
.banner {
    img {
        border: 1px solid $color-lgray-border;
        width: 100%;
    }
}

.expert_photo {
    text-align: center;
    box-shadow: initial;
    border: 1px solid $color-lgray-border;
    background: rgba(255, 255, 255, 0.95);
    min-height: 275px;
    margin-bottom: 21px;

    &_body {
        position: relative;
        margin: 15px;
        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
        }
    }

    &_footer {
        padding: 10px 15px;
        background-color: #fff;
        border-bottom-right-radius: 1px;
        border-bottom-left-radius: 1px;
    }
}

.news {
    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        padding: 15px 0;
        align-content: flex-start;
        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item {
        border: 1px solid $color-lgray-border;
        background: rgba(255, 255, 255, 0.95);
        padding: 15px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        a {
            color: black;
        }
        &__date {
            font-size: 86%;
        }
        &__title {
            margin-top: 0;
            font-family: $font-secondary;
            font-size: 17px;
        }
        &__content {
            p.lead {
                margin: 0 0 0.3em 0;
                font-size: 1.1em;
            }
        }
    }
}

.text-link {
    display: inline-block;
    color: #2ec5cc;
    background: none;
    border: 0;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    &:hover,
    &:focus {
        color: #20898e;
    }
}

.loader-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &--fullpage {
        position: fixed;
    }
}

.cabinet-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin-bottom: 30px;
    @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }
    &__item {
        border-radius: 8px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        padding: 0;
        border: 0;
        overflow: hidden;
        cursor: pointer;
        img {
            display: block;
            width: 100%;
        }
    }
}

.experts-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 1400px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
    @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.panel {
    border-color: #eef1f3;
    box-shadow: initial;
    padding: 15px;
    border-radius: 7px;
    border-style: solid
}
.reviews-grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    h2,
    p {
        margin: 0;
    }
    .text-teal {
        cursor: default;
    }
}

button {
    cursor: pointer;
}

.medfile-footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    @media (max-width: 767px) {
        position: relative;
    }

    .footer-content {
        background-color: #2ec5cc;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        text-align: center;
        @media (max-width: 767px) {
            display: grid;
            grid-gap: 10px;
            justify-items: center;
            position: relative;
            left: -15px;
            width: calc(100% + 30px);
        }
    }

    img {
        width: 142px;
    }

    .button {
        color: #596877;
        background-color: #efefef;
        box-shadow: 0 1px 5px rgba(255, 255, 255, 0.2);
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 12px;
        line-height: 1.42857;
        border-radius: 20px;
        text-decoration: none !important;
        outline: none !important;
        margin-left: 5vw;
        @media (max-width: 767px) {
            margin: 0;
        }
    }

    .description {
        color: #fdfefe;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin: 0 1vw;
    }

    .icon-star {
        line-height: 1;
        font-weight: 300;
    }
}
