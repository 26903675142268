@import './variables.scss';

.review-item {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: white;
    @media (max-width: 500px) {
        padding-left: 0;
        padding-right: 0;
    }

    &-quote {
        padding: 10px 21px;
        margin: 0 0 21px;
        font-size: 18.75px;
        border-left: 5px solid #eee;

        footer {
            font-size: 80%;
            line-height: 1.42857;
            color: #bbb;
        }
    }

    .date {
        color: $color-primary;
        font-weight: 700;
        font-size: 0.9em;
    }
    a {
        color: $color-primary;
        &:hover {
            text-decoration: underline;
        }
    }
}
